<template>
  <div> <b-alert
          variant="primary"
          show
        >
          <div class="alert-body">
            <span><strong>Bilgilendirme:</strong>Firmaya yetkili eklediğinizde aşağıdaki kaydet butonuna basmanıza gerek kalmaksızın eklenecektir. Firmaya ait diğer bilgileri güncellediğinizde kaydet butonuna basmanız gerekmektedir</span>
          </div>
        </b-alert>
    <b-row>

      <b-col md="6">
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            v-if="customerData"
            class="p-2"

            @submit.prevent="handleSubmit(saveData)"
            @reset.prevent="resetForm"
          >  <validation-provider
               #default="validationContext"
               :name="$t('Cari Kodu')"
               rules="required"
             >
               <b-form-group
                 :label="$t('Cari Kodu')"
                 label-for="uyum_code"
               >
                 <b-form-input
                   id="uyum_code"
                   v-model="customerData.uyum_code"
                   autofocus
                   :readonly="customerData.creator!=1"
                   autocomplete="off"
                   :state="getValidationState(validationContext)"
                   trim
                 />

                 <b-form-invalid-feedback>
                   {{ validationContext.errors[0] }}
                 </b-form-invalid-feedback>
               </b-form-group>
             </validation-provider>
            <!-- Firma ad -->
            <validation-provider
              #default="validationContext"
              :name="$t('Firma Adı')"
              rules="required|min:2"
            >
              <b-form-group
                :label="$t('Firma Adı')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="customerData.name"
                  autofocus
                  :readonly="customerData.creator!=1"
                  autocomplete="off"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Firma ad -->
            <validation-provider
              #default="validationContext"
              :name="$t('Adres')"
              rules="required|min:5"
            >
              <b-form-group
                :label="$t('Adres')"
                label-for="address"
              >
                <b-form-textarea
                  id="address"
                  v-model="customerData.address"
                  :readonly="customerData.creator!=1"
                  autofocus
                  :state="getValidationState(validationContext)"
                  rows="3"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Web sitesi -->
            <validation-provider
              #default="validationContext"
              :name="$t('Firma Web Adresi')"
              rules="min:3"
            >
              <b-form-group
                :label="$t('Firma Web Adresi')"
                label-for="website"
              >
                <b-form-input
                  id="website"
                  v-model="customerData.website"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Ülke -->
            <b-form-group
              v-if="countryOptions"
              :label="$t('Ülke')"
              label-for="country"
            >
              <v-select
                id="country"
                v-model="customerData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="country"
              />
            </b-form-group>
            <!-- Şehir -->
            <validation-provider
              #default="validationContext"
              :name="$t('Şehir')"
              rules="min:3"
            >
              <b-form-group
                :label="$t('Şehir')"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="customerData.city"
                  :readonly="customerData.creator!=1"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Satış Temsilcisi -->
            <b-form-group
              v-if="usersData"
              :label="$t('Satış Temsilcisi')"
              label-for="user_id"
            >
              <v-select
                id="user_id"
                v-model="customerData.user_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="usersData"
                :reduce="val => val.value"
                :clearable="false"
                :selectable="false"
                :disabled="true"
                input-id="user_id"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="is_black_list"
                v-model="customerData.is_black_list"
                class="custom-control-danger"
                name="check-button"
                :disabled="customerData.creator!=1"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t('Kara Listeye Dahil Et') }}
              </b-form-checkbox>

            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="is_potential"
                v-model="customerData.is_potential"
                class="custom-control-warning"
                name="check-button"
                :disabled="customerData.creator!=1"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t('Potansiyel Müşteri') }}
              </b-form-checkbox>

            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="is_send_bulk_mail"
                v-model="customerData.is_send_bulk_mail"
                class="custom-control-primary"
                name="check-button"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t('Toplu Mail Gönderilebilir') }}
              </b-form-checkbox>

            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                id="is_ecommerce"
                v-model="customerData.is_ecommerce"
                class="custom-control-primary"
                name="check-button"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t('B2B Üzeirnde Listelensin') }}
              </b-form-checkbox>

            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t('Kaydet') }}
              </b-button>
            </div>
          </b-form>

        </validation-observer>
      </b-col>
      <b-col
        md="6"
      >
        <manager-editor

          :managers="managersList"
          :customer-id="customerData.id"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow, BCol, BButton, BFormTextarea, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import managerEditor from './company-managers/CompanyManagers.vue'

export default {
  components: {
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BAlert,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    managerEditor,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isEditCustomerSidebarActive',
    event: 'update:is-edit-customer-sidebar-active',
  },
  props: {
    blankCustomerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,

    }
  },
  watch: {
  },
  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    makeCustomerData() {
      this.customerData = JSON.parse(JSON.stringify(this.blankCustomerData))
    },
    saveData() {
      store.dispatch('app-customer/updateCustomer', this.customerData)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    // fetch user
    const countryOptions = ref(null)
    const managersList = ref([])
    const customerData = ref(null)
    store.dispatch('app/fetchCountries', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({ label: val.name, value: val.code }))
        countryOptions.value = arr
      })
    const usersData = ref(null)
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })

        usersData.value = arr
      })
      .catch(() => {
      })
    store.dispatch('app-customer/fetchCustomerManagers', { customer_id: props.blankCustomerData.id })
      .then(response => {
        managersList.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          managersList.value = undefined
        }
      })
    const onSubmit = () => {

    }
    const resetcustomerData = () => {
      customerData.value = JSON.parse(JSON.stringify(props.blankCustomerData))
    }
    resetcustomerData()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetcustomerData)

    return {
      onSubmit,
      countryOptions,
      refFormObserver,
      getValidationState,
      usersData,
      managersList,
      customerData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

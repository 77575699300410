<template>
  <div>
    <!-- modal -->
    <b-modal
      id="titles-modal"
      v-model="showManagerTitleEditor"
      size="lg"
      hide-footer
      :title="$t('Firma Yönetici Ünvanları')"
    >
      <manager-titles
        :show="showManagerTitleEditor"
        @dataChanged="titleDataChanged"
      /></b-modal>
    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      v-model="modalShow"
      :ok-title="$t('Kaydet')"
      cancel-variant="outline-secondary"
      hide-footer
      @show="resetModal"
      @hidden="resetModal"
    >
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(saveData)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.titles-modal
                  variant="flat-primary"
                >
                  <feather-icon icon="EditIcon" />
                  {{ $t('Ünvanları Düzenle') }}
                </b-button>
              </div>
              <b-form-group

                :label="$t('Yetkili Ünvanı')"
                label-for="title_id"
                label-cols-md="4"
              >

                <v-select
                  v-if="managerTitlesData"
                  id="title_id"
                  v-model="customerData.title_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="managerTitlesData"
                  :reduce="val => val.value"
                  :clearable="false"
                  :selectale="customerData.creator==1"
                  input-id="title_id"
                />

              </b-form-group>
            </b-col>
            <!-- Firma ad -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="$t('Yetkili Adı')"
                rules="required|min:2"
              >
                <b-form-group
                  :label="$t('Yetkili Adı')"
                  label-for="name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="name"
                    v-model="customerData.name"
                    autofocus
                    :readonly="customerData.creator!=1"
                    autocomplete="off"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="$t('Soyadı')"
                rules="min:2"
              >
                <b-form-group
                  :label="$t('Soyadı')"
                  label-for="surname"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="surname"
                    v-model="customerData.surname"
                    :readonly="customerData.creator!=1"
                    autofocus
                    autocomplete="off"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="$t('Telefon')"
                rules="min:2"
              >
                <b-form-group
                  :label="$t('Telefon')"
                  label-for="phone"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="phone"
                    v-model="customerData.phone"
                    autofocus
                    :readonly="customerData.creator!=1"
                    autocomplete="off"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="$t('2. Telefon')"
                rules="min:2"
              >
                <b-form-group
                  :label="$t('2. Telefon')"
                  label-for="phone2"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="phone2"
                    v-model="customerData.phone2"
                    autofocus
                    :readonly="customerData.creator!=1"
                    autocomplete="off"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="$t('Dahili Telefon')"
              >
                <b-form-group
                  :label="$t('Dahili Telefon')"
                  label-for="internal_phone"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="internal_phone"
                    v-model="customerData.internal_phone"
                    autofocus
                    :readonly="customerData.creator!=1"
                    autocomplete="off"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="$t('Cep Telefonu')"
                rules="min:2"
              >
                <b-form-group
                  :label="$t('Cep Telefonu')"
                  label-for="mobile_phone"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="mobile_phone"
                    v-model="customerData.mobile_phone"
                    autofocus
                    :readonly="customerData.creator!=1"
                    autocomplete="off"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="$t('Email')"
                rules="email"
              >
                <b-form-group
                  :label="$t('Email')"
                  label-for="email"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="email"
                    v-model="customerData.email"
                    autofocus
                    :readonly="customerData.creator!=1"
                    autocomplete="off"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="$t('2. Email')"
                rules="email"
              >
                <b-form-group
                  :label="$t('2. Email')"
                  label-for="email2"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="email2"
                    v-model="customerData.email2"
                    :readonly="customerData.creator!=1"
                    autofocus
                    autocomplete="off"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label-cols-md="4"
              >
                <b-form-checkbox
                  id="is_accounting_manager"
                  v-model="customerData.is_accounting_manager"
                  :disabled="customerData.creator!=1"
                  class="custom-control-primary"
                  name="check-button"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t('Bu Yetkili Bir Muhasebe Yetkilisidir') }}
                </b-form-checkbox>

              </b-form-group>

            </b-col></b-row>
          <hr>
          <b-alert
            v-if="customerData.creator!=1"
            variant="warning"
            show
          >
            <div class="alert-body">
              <span>{{ $t('Bu Veri Sistem Tarafından Otomatik Oluşturulduğu İçin Düzenlenemez ve Silinemez') }}</span>
            </div>
          </b-alert>
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              v-if="customerData.creator==1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              {{ $t('Kaydet') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </b-modal>
    <div class="d-flex align-items-center justify-content-end">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="flat-primary"
        @click="openModal('add')"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Yetkili Ekle') }}
      </b-button>
    </div>
    <b-list-group v-if="managersData">
      <b-list-group-item
        v-for="(item,index) in managersData"
        :id="item.id"
        :key="item.id"
        class="d-flex justify-content-between align-items-center"
      >
        <span>{{ item.name }} - {{ item.phone }}</span>
        <div> <b-badge
                v-if="item.creator==2"
                variant="info"
                class="badge-round"
              >
                {{ $t('Otomatik Oluşturulan') }}
              </b-badge>
          <b-dropdown
            variant="link"
            no-caret
            size="sm"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="12"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="openModal('edit',index)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Düzenle') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="item.creator==1"
              variant="danger"
              @click="deleteData(index)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">{{ $t('Sil') }}</span>
            </b-dropdown-item>
          </b-dropdown></div>

      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BButton,
  BModal, VBModal, BRow, BAlert, BDropdown, BDropdownItem, BCol, BListGroupItem, BFormInput, BBadge, BListGroup, BFormInvalidFeedback, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import vSelect from 'vue-select'
import managerTitles from './ManagerTitles.vue'

export default {
  components: {
    BModal,
    BForm,
    BBadge,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    vSelect,
    BListGroup,
    BAlert,
    BCol,
    BDropdown,
    BDropdownItem,
    BRow,
    managerTitles,
    BListGroupItem,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'modalShow',
    event: 'update:customer-manager-edit-modal-show',
  },

  props: {
    managers: {
      type: Array,
      required: true,
    },
    customerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      blankCustomerData: {
        customer_id: 0,
        name: '',
        surname: '',
        phone: '',
        phone2: '',
        internal_phone: '',
        mobile_phone: '',
        email: '',
        email2: '',
        is_accounting_manager: false,
        title_id: 0,
        creator: 1,
      },
      modalShow: false,
      componentMode: 'add',
      customerData: {},
      showManagerTitleEditor: false,
      managerTitlesData: [],
      managersData: [],
      selectedIndex: -1,
      createdBySystem: false,
    }
  },
  watch: {
    managers(val) {
      this.managersData = JSON.parse(JSON.stringify(val))
    },
    modalShow(val) {
      if (val) {
        this.fetchTitleData()
      }
    },
  },
  created() {
    this.blankCustomerData.customer_id = this.customerId
    this.resetcustomerData()
  },
  methods: {
    deleteData(index) {
      const selectedData = this.managersData[index]
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Firma Yetkilisi Silindiğinde Bu Verinin Kullanıldığı  Müşteri Verileri Bozulabilir.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-customer/deleteCustomerManager', { id: selectedData.id })
            .then(() => {
              if (index > -1) {
                this.managersData.splice(index, 1)
              }
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    openModal(mode, dataIndex = -1) {
      this.resetcustomerData()

      this.componentMode = mode
      if (mode === 'edit' && dataIndex > -1) {
        this.selectedIndex = dataIndex
        this.customerData = JSON.parse(JSON.stringify(this.managersData[dataIndex]))
        this.createdBySystem = this.customerData.creator !== 1
      }
      this.modalShow = true
    },
    saveData() {
      if (this.componentMode === 'add') {
        store.dispatch('app-customer/addCustomerManager', this.customerData)
          .then(response => {
            this.managersData.push(response.data.data)
            this.modalShow = false
            this.resetcustomerData()
          })
      } else if (this.componentMode === 'edit') {
        store.dispatch('app-customer/updateCustomerManager', this.customerData)
          .then(() => {
            this.managersData[this.selectedIndex] = JSON.parse(JSON.stringify(this.customerData))
          })
      }
    },
    titleDataChanged(titles) {
      this.managerTitlesData = []
      titles.forEach(val => {
        this.managerTitlesData.push({ label: val.name, value: val.id })
      })
    },
    fetchTitleData() {
      store.dispatch('app-customer/fetchManagerTitles', {})
        .then(response => {
          this.managerTitlesData = []
          response.data.data.forEach(val => {
            this.managerTitlesData.push({ label: val.name, value: val.id })
          })
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.managerTitlesData = null
          }
        })
    },
    resetModal() {},
    resetcustomerData() {
      this.customerData = JSON.parse(JSON.stringify(this.blankCustomerData))
      this.selectedIndex = -1
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const onSubmit = () => {

    }
    const resetcustomerData = () => {
      this.customerData.value = JSON.parse(JSON.stringify(this.blankCustomerData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcustomerData)

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

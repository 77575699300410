import { render, staticRenderFns } from "./CompanyManagers.vue?vue&type=template&id=052c2c12&"
import script from "./CompanyManagers.vue?vue&type=script&lang=js&"
export * from "./CompanyManagers.vue?vue&type=script&lang=js&"
import style0 from "./CompanyManagers.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
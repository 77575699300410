<template>
  <component :is="customerData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="customerData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <fieldset :disabled="!$can('read', 'customer_edit')">
      <b-tabs
        v-if="customerData"
        pills
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Firma Ayarları') }}</span>
          </template>
          <customer-edit-tab-information
            :blank-customer-data="customerData"
            class="mt-2 pt-75"
          />
        </b-tab>
        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Firma Belgeleri') }}</span>
          </template>
          <customer-edit-tab-documents
            v-if="customerData"
            :customer-id="customerData.id"
            class="mt-2 pt-75"
          />
        </b-tab>
      </b-tabs>
    </fieldset>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import customerStoreModule from '../customerStoreModule'
import customerEditTabInformation from './CustomerEditTabInformation.vue'
import customerEditTabDocuments from './CustomerEditTabDocuments.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    router,
    customerEditTabInformation,
    customerEditTabDocuments,
  },
  setup() {
    const customerData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })
    store.dispatch('app-customer/fetchCustomer', { id: router.currentRoute.params.id })
      .then(response => {
        customerData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          customerData.value = undefined
        }
      })

    return {
      customerData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<template>
  <div>

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          @click="addNewData"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>{{ $t('Ünvan Ekle') }}</span>
        </b-button>
        <hr>
        <!-- Row Loop -->
        <b-row>
          <b-col md="8">
            <span>Ünvan İsmi</span>
          </b-col>
          <b-col md="4" />
        </b-row>
        <b-row><b-col md="12" /></b-row>
        <b-row
          v-for="(item, index) in managerTitleData"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Item Name -->
          <b-col md="8">
            <b-form-group>
              <b-form-input
                id="item-name"
                v-model="item.name"
                type="text"
                size="sm"
                :placeholder="$t('Ünvan İsmi Giriniz.')"
                :readonly="item.creator!=1"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="item.creator!=1"
            md="4"
            class="mb-50"
          >
            <b-badge
              v-if="item.creator==2"
              variant="info"
              class="badge-round"
            >
              {{ $t('Otomatik Oluşturulan') }}
            </b-badge>
          </b-col>
          <b-col
            v-if="item.creator==1"
            md="2"
            class="mb-50"
          >

            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              size="sm"
              @click="deleteData(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>{{ $t('Sil') }}</span>
            </b-button>
          </b-col>
          <b-col
            v-if="item.creator==1"
            md="2"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="primary"
              size="sm"
              @click="saveData(index)"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-25"
              />
              <span>{{ $t('Kaydet') }}</span>
            </b-button>
          </b-col>

        </b-row>

      </b-form>
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <span>{{ $t('Sistem Tarafından Otomatik Oluşturulan Veriler Düzenlenemez ve Silinemez') }}</span>
        </div>
      </b-alert>
    </validation-observer>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, VBModal, BAlert, BFormInput, BButton, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BForm,
    BAlert,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationObserver,
    BCol,
    BBadge,
    BRow,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isEditCustomerSidebarActive',
    event: 'update:is-edit-customer-sidebar-active',
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      blankCustomerData: {
        name: '',
        creator: 1,
      },
      managerTitleData: [],
      dataFetched: false,
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.fetchData()
      } else if (this.dataFetched) {
        this.$emit('dataChanged', this.managerTitleData)
      }
    },
  },
  methods: {
    deleteData(index) {
      const selectedData = this.managerTitleData[index]
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Ünvan Silindiğinde Ünvana Bağlı Olan Müşteri Verileri Bozulabilir.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-customer/deleteCustomerManagerTitle', { id: selectedData.id })
            .then(() => {
              if (index > -1) {
                this.managerTitleData.splice(index, 1)
              }
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    saveData(index) {
      const selectedData = this.managerTitleData[index]
      store.dispatch('app-customer/updateCustomerManagerTitle', selectedData)
        .then(() => {
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    addNewData() {
      store.dispatch('app-customer/addCustomerManagerTitle', {})
        .then(response => {
          this.managerTitleData.unshift(response.data.data)
        })
    },
    fetchData() {
      store.dispatch('app-customer/fetchManagerTitles', {})
        .then(response => {
          this.dataFetched = true
          this.managerTitleData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.blankCustomerData.value = undefined
          }
        })
    },
    resetcustomerData() {
      this.customerData = JSON.parse(JSON.stringify(this.blankCustomerData))
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const onSubmit = () => {
    }
    const resetcustomerData = () => {
      this.customerData.value = JSON.parse(JSON.stringify(this.blankCustomerData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcustomerData)

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

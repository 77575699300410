import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addCustomersBulk(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/customers-add-bulk', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/customers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchManagerTitles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/customermanagertitles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomerManagerTitle(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/customermanagertitles', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomerManagerTitle(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/customermanagertitles/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomerManagerTitle(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/customermanagertitles/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/customers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomerManager(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/customermanagers', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomerManager(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/customermanagers/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomerManager(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/customermanagers/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerManagers(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/customermanagers', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/customers', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/customers/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerDocuments(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/customerfiles', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomerDocument(ctx, d) {
      return new Promise((resolve, reject) => {
        const headers = {
          'Content-Type': 'multipart/form-data',
        }
        axios
          .post('/api/customerfiles', d, { headers })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomerDocument(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/customerfiles/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
